/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #e7e7e7;
}

.semibold, .semibold_a, .semibold_a * { font-weight: 600; }

span.required { color: #f07f00; font-weight: 700; }

.bgclgray, .bgclgray_h:hover { background-color: #f4f4f5; }
.bgclgray_i, .bgclgray_h_i:hover { background-color: #f4f4f5 !important; }
.bgcgray, .bgcgray_h:hover { background-color: #e7e7e7; }
.bgcgray_i, .bgcgray_h_i:hover { background-color: #e7e7e7 !important; }
.bgcmgray, .bgcmgray_h:hover { background-color: #959595; }
.bgcmgray_i, .bgcmgray_h_i:hover { background-color: #959595 !important; }
.bgcdgray, .bgcdgray_h:hover { background-color: #2f2f2f; }
.bgcdgray_i, .bgcdgray_h_i:hover { background-color: #2f2f2f !important; }
.bgcorange, .bgcorange_h:hover { background-color: #f07f00; }
.bgcorange_i, .bgcorange_h_i:hover { background-color: #f07f00 !important; }
.bgcdorange, .bgcdorange_h:hover { background-color: #e15c01; }
.bgcdorange_i, .bgcdorange_h_i:hover { background-color: #e15c01 !important; }
.bgcred, .bgcred_h:hover { background-color: #b22222; }
.bgcred_i, .bgcred_h_i:hover { background-color: #b22222 !important; }
.bgcblue, .bgcblue_h:hover { background-color: #4f9ffe; }
.bgcblue_i, .bgcblue_h_i:hover { background-color: #4f9ffe !important; }
.bgcpurple, .bgcpurple_h:hover { background-color: #f381f5; }
.bgcpurple_i, .bgcpurple_h_i:hover { background-color: #f381f5 !important; }
.bgcgreen, .bgcgreen_h:hover { background-color: #0de48a; }
.bgcgreen_i, .bgcgreen_h_i:hover { background-color: #0de48a !important; }

.clgray, .clgray_h:hover { color: #f4f4f5; }
.clgray_i, .clgray_h_i:hover { color: #f4f4f5 !important; }
.cgray, .cgray_h:hover { color: #e7e7e7; }
.cgray_i, .cgray_h_i:hover { color: #e7e7e7 !important; }
.cmgray, .cmgray_h:hover { color: #959595; }
.cmgray_i, .cmgray_h_i:hover { color: #959595 !important; }
.cdgray, .cdgray_h:hover { color: #2f2f2f; }
.cdgray_i, .cdgray_h_i:hover { color: #2f2f2f !important; }
.corange, .corange_h:hover { color: #f07f00; }
.corange_i, .corange_h_i:hover { color: #f07f00 !important; }
.cdorange, .cdorange_h:hover { color: #e15c01; }
.cdorange_i, .cdorange_h_i:hover { color: #e15c01 !important; }
.cred, .cred_h:hover { color: #b22222; }
.cred_i, .cred_h_i:hover { color: #b22222 !important; }
.cblue, .cblue_h:hover { color: #4f9ffe; }
.cblue_i, .cblue_h_i:hover { color: #4f9ffe !important; }
.cpurple, .cpurple_h:hover { color: #f381f5; }
.cpurple_i, .cpurple_h_i:hover { color: #f381f5 !important; }
.cgreen, .cgreen_h:hover { color: #0de48a; }
.cgreen_i, .cgreen_h_i:hover { color: #0de48a !important; }

.brclgray, .brclgray_h:hover { border-color: #f4f4f5; }
.brclgray_i, .brclgray_h_i:hover { border-color: #f4f4f5 !important; }
.brcgray, .brcgray_h:hover { border-color: #e7e7e7; }
.brcgray_i, .brcgray_h_i:hover { border-color: #e7e7e7 !important; }
.brcmgray, .brcmgray_h:hover { border-color: #959595; }
.brcmgray_i, .brcmgray_h_i:hover { border-color: #959595 !important; }
.brcdgray, .brcdgray_h:hover { border-color: #2f2f2f; }
.brcdgray_i, .brcdgray_h_i:hover { border-color: #2f2f2f !important; }
.brcorange, .brcorange_h:hover { border-color: #f07f00; }
.brcorange_i, .brcorange_h_i:hover { border-color: #f07f00 !important; }
.brcdorange, .brcdorange_h:hover { border-color: #e15c01; }
.brcdorange_i, .brcdorange_h_i:hover { border-color: #e15c01 !important; }
.brcred, .brcred_h:hover { border-color: #b22222; }
.brcred_i, .brcred_h_i:hover { border-color: #b22222 !important; }
.brcblue, .brcblue_h:hover { border-color: #4f9ffe; }
.brcblue_i, .brcblue_h_i:hover { border-color: #4f9ffe !important; }
.brcpurple, .brcpurple_h:hover { border-color: #f381f5; }
.brcpurple_i, .brcpurple_h_i:hover { border-color: #f381f5 !important; }
.brcgreen, .brcgreen_h:hover { border-color: #0de48a; }
.brcgreen_i, .brcgreen_h_i:hover { border-color: #0de48a !important; }

p { margin-bottom: 22px; text-align: justify; }
p.before-ul { margin-bottom: 12px; }
p + p { margin-top: -8px; }

.link[href] {
  color: #b22222;
  text-decoration: underline;
  cursor: pointer;
}
  .link[href]:hover {
    text-decoration: none;
  }


.form-block-container {
  background: #fff;
  border: 4px solid #f07f00;
  padding: 30px;
}
  .form-popup-container .form-block-container {
    width: 370px;
  }

  .form-block-container .subsubheader {
    padding-top: 0px;
  }

  .form {
    position: relative;
  }
    .form-row + .form-row {
      margin-top: 20px;
    }
      .form-label {
        margin-bottom: 10px;
      }

      .input-container {
        background-color: #f4f4f5;
        border: 1px solid #e7e7e7;
        padding: 8px 10px;
        position: relative;
        z-index: 1;
      }
        .input-container input,
        .input-container textarea,
        .input-container select {
          color: #2f2f2f;
          background: transparent;
          font-size: 1em;
          font-weight: 400;
          line-height: 1.3;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
        .input-container input:not([type="radio"]):not([type="checkbox"]),
        .input-container textarea,
        .input-container select {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }

      .form-error {
        position: relative;
      }
        .errorMessage {
          color: #fff;
          background: #f07f00;
          border-radius: 4px;
          font-size: 11px;
          line-height: 1.3;
          padding: 2px 5px 3px;
          margin: 2px 0px 0px;
        }

    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;
      margin: 8px 0px 0px -14px;
    }
      .form-button {
        padding: 14px 0px 0px 14px;
      }



.button-style {
  display: inline-block;
  color: #f07f00;
  background: transparent;
  border: 1px solid #f07f00;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  min-width: 110px;
  padding: 8px 18px;
  cursor: pointer;
  position: relative;
}
  .button-style[disabled],
  .button-style:disabled,
  .button-style.disabled {
    color: #959595 !important;
    background: #e7e7e7 !important;
    border-color: transparent;
    cursor: default;
  }

  .button-style.blue {
    color: #4f9ffe;
    border-color: #4f9ffe;
  }

  .button-style:hover,
  .button-style.active {
    color: #fff;
    background: #f07f00;
  }
    .button-style.blue:hover,
    .button-style.blue.active {
      color: #fff;
      background: #4f9ffe;
    }


  .button-style > * {
    display: block;
  }


ul.ul, ul.ul-dot, ol.ol {
  margin: -10px 0px 22px;
}
ul.ul-dot {
  margin-top: -6px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ul.ul ul.ul-dot, ul.ul-dot ul.ul, ul.ul-dot ul.ul-dot, ol.ol ol.ol {
    margin-top: 0px;
    margin-bottom: 4px;
  }
  ul.ul li, ul.ul-dot li, ol.ol li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    padding-top: 10px;
    padding-bottom: 0px;
    position: relative;
  }
  ul.ul-dot li {
    padding-top: 6px;
  }
    ul.ul li:before,
    ul.ul-dot ul.ul li:before {
      content: "●";
      color: transparent;
      display: inline-block;
      background: url('/i/ul-list-style-image.svg') no-repeat 50% 50% transparent;
      background-size: contain;
      margin-right: 10px;
      width: 19px;
      min-width: 19px;
    }
    ul.ul-dot li:before,
    ul.ul ul.ul-dot li:before {
      content: "●";
      color: #f07f00;
      display: inline-block;
      background: none;
      margin-right: 10px;
      width: auto;
      min-width: auto;
    }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      font-weight: 600;
      margin-right: 10px;
      /*color: #f07f00;*/
    }

    ul.ul li p:last-child,
    ul.ul-dot li p:last-child,
    ol.ol li p:last-child {
      margin-bottom: 0px;
    }


a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:after {
    content: "";
    background: url('/i/gallery-image-zoom.svg') 100% 100% no-repeat transparent;
    background-size: contain;
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 10%;
    height: 10%;
    opacity: 0.8;
    z-index: 3;

    -webkit-transition: width .3s ease-in-out 0s, height .3s ease-in-out 0s, opacity .3s ease-in-out 0s;
    -moz-transition: width .3s ease-in-out 0s, height .3s ease-in-out 0s, opacity .3s ease-in-out 0s;
    -o-transition: width .3s ease-in-out 0s, height .3s ease-in-out 0s, opacity .3s ease-in-out 0s;
    transition: width .3s ease-in-out 0s, height .3s ease-in-out 0s, opacity .3s ease-in-out 0s;
  }
    a.zoom-image:hover:after {
      width: 15%;
      height: 15%;
      opacity: 1 !important;
    }


/*.unwrapper {
  margin-left: -9999px;
  margin-right: -9999px;
  position: static;
}
.wrapper {
  width: 1206px;
  position: relative;
  margin: 0px auto;
  padding: 0px;
}
  .main-rotator-container + .wrapper {
    z-index: 2;
  }*/



/* Structure */
.structure {
  width: 1206px;
  margin: 0px auto;
  overflow: hidden;
}
  .structure-wrapper {
    background: #fff;
    padding: 0px 28px;
    position: relative;
    z-index: 5;
  }

  /* Header */
  .header {
    position: relative;
    z-index: 5;
  }
    .header-info-menu {
      padding: 18px 0px -0px;
    }
      .header-info-menu > ul > li > div {
        display: none;
      }

      .header-info-menu > ul > li > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #f4f4f5;
        border: 1px solid #e7e7e7;
        line-height: 1.1;
      }
        .header-info-menu > ul > li > ul > li {
          flex-grow: 1;
          position: relative;
        }
        .header-info-menu > ul > li > ul > li + li:before {
          content: "";
          display: block;
          background: #e7e7e7;
          width: 1px;
          position: absolute;
          top: 14px;
          left: -1px;
          bottom: 14px;
        }
          .header-info-menu > ul > li > ul > li > ul {
            display: none;
          }

          .header-info-menu > ul > li > ul > li > div > div > a,
          .header-info-menu > ul > li > ul > li > div > div > span {
            color: #2f2f2f;
            display: block;
            padding: 16px 10px;
            text-align: center;
            white-space: nowrap;
          }
            .header-info-menu > ul > li > ul > li > div > div > a {
              cursor: pointer;
            }
              .header-info-menu > ul > li > ul > li > div > div > a:hover,
              .header-info-menu > ul > li > ul > li.active > div > div > a {
                text-decoration: underline;
              }
              .header-info-menu > ul > li > ul > li.active > div > div > a {
                color: #b22222;
                font-weight: 600;
              }


    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0px;
    }
      .header-logo {
        min-width: 264px;
        max-width: 264px;
      }
        .header-logo img {
          width: 100%;
        }


      .search-form-container {
        flex-grow: 1;
        flex-shrink: 1;
        margin-left: 30px;
      }
        .search-form-wrapper {
          position: relative;
        }
          .search-button-container {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 1;
          }
            .search-button {
              display: inline-block;
              background: #f4f4f5;
              border: solid #e7e7e7;
              border-width: 1px 1px 1px 0px;
              font-size: 14px;
              position: absolute;
              top: 0px;
              right: 0px;
              bottom: 0px;
              min-width: 0px;
              padding: 0px 4px;
              margin: 0px;
              cursor: pointer;

            }
              .search-button span {
                color: transparent;
                background: url('/i/search-button-icon.svg') no-repeat 50% 50%/contain transparent;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: 8px;
                z-index: 1;
              }

              .search-button img {
                height: 100%;
              }


        .search-form-results-container {
          display: none;
        }


      .header-contacts {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 1;
        margin-left: 25px;
      }
        .header-contacts > div {
          text-align: center;
          min-height: 24px;
          margin-left: 45px;
        }

        .header-address-container,
        .header-phones-container {
          padding-left: 40px;
          position: relative;
        }
          .header-address-container:before,
          .header-phones-container:before {
            content: "";
            background: url('/i/header-icon-address.svg') no-repeat 50% 50% transparent;
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            width: 24px;
          }
          .header-phones-container:before {
            background-image: url('/i/header-icon-phone.svg');
          }


          .header-address {
            font-size: 16px;
          }

          .header-phone {
            font-size: 20px;
            white-space: nowrap;
          }
            .header-phone + .header-phone {
              margin-top: 4px;
            }

            .header-phone a {
              cursor: text;
            }

          .header-address + .contact-link,
          .header-phone + .callback-link {
            margin-top: 4px;
          }
            .header-contacts .contact-link a,
            .header-contacts .callback-link a {
              color: #b22222;
              display: inline-block;
              border-bottom: 1px solid #b22222;
              line-height: 1;
              text-decoration: none;
              cursor: pointer;
            }
            .header-contacts .callback-link a {
              border-bottom-style: dashed;
            }
              .header-contacts .contact-link a:hover,
              .header-contacts .callback-link a:hover {
                border-bottom-color: transparent;
              }


    .header-product-menu {
      line-height: 1.1;
      position: relative;
    }
      .header-menu-button-container,
      .header-menu-checkbox {
        display: none;
      }

      .header-product-menu > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #f07f00;
        border: 1px solid #e15c01;
        /*line-height: 1.1;*/
      }
        .header-product-menu > ul > li {
          flex-grow: 1;
        }
          .header-product-menu > ul > li > div {
            position: relative;
          }
            .header-product-menu > ul > li + li > div:before {
              content: "";
              display: block;
              background: #fff;
              width: 1px;
              position: absolute;
              top: 14px;
              left: -1px;
              bottom: 14px;
            }

            .header-product-menu ul > li > div > .dropmarker {
              display: none;
            }

            .header-product-menu > ul > li > div > div > a,
            .header-product-menu > ul > li > div > div > span {
              color: #fff;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 10px;
              cursor: default;
              white-space: nowrap;
              position: relative;
            }
              .header-product-menu > ul > li > div > div > a {
                cursor: pointer;
              }
                .header-product-menu > ul > li:hover > div > div > a,
                .header-product-menu > ul > li.active > div > div > a,
                .header-product-menu > ul:hover > li.active:hover > div > div > a {
                  background: #e15c01;
                }
                .header-product-menu > ul > li.active > div > div > a,
                .header-product-menu > ul > li.with-active > div > div > a {
                  font-weight: 700;
                }
                  .header-product-menu > ul:hover > li.active > div > div > a {
                    background: transparent;
                  }

                .header-product-menu > ul > li > div > div > a > span,
                .header-product-menu > ul > li > div > div > span > span {
                  background: no-repeat top center/cover;
                  position: relative;
                  max-width: 30px;
                  min-width: 30px;
                  margin-right: 8px;
                  overflow: hidden;
                }
                  .header-product-menu > ul > li > div > div > a > span:before,
                  .header-product-menu > ul > li > div > div > span > span:before {
                    content: "";
                    display: block;
                    position: relative;
                    height: 0px;
                    padding-top: 100%;
                  }

          .header-product-menu >  ul > li > ul {
            display: none;
            /*flex-flow: row wrap;*/
            flex-direction: row;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
            font-size: 12px;
            background: #f4f4f5;
            border: solid #e7e7e7;
            border-width: 0px 1px 1px;
            padding: 0px 0px 24px;
            margin: -1px 0px 0px;
            position: absolute;
            top: 100%;
            left: 0px;
            right: 0px;
          }
            .header-product-menu > ul > li:hover > ul/*,

            .header-product-menu > ul > li:nth-child(1) > ul*/ {
              display: flex;
            }

            .header-product-menu > ul > li > ul > li {
              /*width: 33.333333%;*/
              flex: 100% 1 19;
              padding: 20px 12px 0px 22px;
            }
            .header-product-menu > ul > li > ul > .service-item,
            .header-product-menu > ul > li > ul > .brands-item {
              flex-shrink: 20;
            }
              .header-product-menu > ul > li > ul > li .dropmarker {
                display: none;
              }
              .header-product-menu > ul > li > ul > li > div > div > a,
              .header-product-menu > ul > li > ul > li > div > div > span {
                font-size: 14px;
                font-weight: 700;
              }

              .header-product-menu > ul > li > ul > li > div > div > span {
                display: inline-flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                z-index: 1;
              }
                .header-product-menu > ul > li > ul > li > div > div > span > span:first-child {
                  background: no-repeat bottom center/cover;
                  position: relative;
                  max-width: 26px;
                  min-width: 26px;
                  margin-right: 12px;
                  overflow: hidden;
                }
                .header-product-menu > ul > li > ul > .service-item > div > div > span > span:first-child {
                  background-image: url('/i/product-menu-icon-service.svg');
                }
                .header-product-menu > ul > li > ul > .brands-item > div > div > span > span:first-child {
                  background-image: url('/i/product-menu-icon-brands.svg');
                }

                  .header-product-menu > ul > li > ul > li > div > div > span > span:first-child:before {
                    content: "";
                    display: block;
                    position: relative;
                    height: 0px;
                    padding-top: 100%;
                  }


                .header-product-menu > ul > li > ul > li > div > div > span > span + span {
                  display: inline-block;
                  padding: 5px 0px;
                  border-bottom: 1px solid #959595;
                }

              .header-product-menu > ul > li > ul li > div a {
                text-decoration: none;
                cursor: pointer;
              }
                .header-product-menu > ul > li > ul li > div a:hover,
                .header-product-menu > ul > li > ul li.active > div a {
                  color: #b22222;
                  text-decoration: underline;
                }
                .header-product-menu > ul > li > ul li.active > div a {
                  cursor: default;
                }

              .header-product-menu > ul > li > ul > li > ul {
                padding: 6px 0px 0px 38px;
              }
              .header-product-menu > ul > li > ul > .brands-item > ul {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-content: flex-start;
                align-items: flex-start;
                margin-left: -11px;
              }

                .header-product-menu > ul > li > ul > li > ul > li  {
                  padding: 11px 0px 0px;
                }
                .header-product-menu > ul > li > ul > .brands-item > ul > li {
                  padding-left: 11px;
                }
  /* Header end */



  /* Content block */
  .content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 0px 0px 0px -30px;
    position: relative;
    z-index: 3;
  }
    .left-column {
      flex: 1 0 auto;
      padding: 25px 0px 0px 30px;
      width: 294px;
      max-width: 294px;
      min-width: 294px;
      order: 1;
    }
    .left-column,
    .left-column-block {
      position: relative;
      z-index: 5;
    }
      .left-column-block {
        padding: 0px 0px 35px;
      }


    .content {
      flex: 1 0 auto;
      max-width: 100%;
      padding: 25px 0px 0px 30px;
      position: relative;
      order: 2;
      z-index: 3;

      /*min-height: 3000px;*/
    }
      .with-left .content {
        max-width: calc(100% - 294px);
      }

      .main-rotator-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        padding: 0px 0px 10px;
        position: relative;
        z-index: 0;
      }
        .main-rotator-wrapper {
          width: 100%;
          max-height: 350px;
          position: relative;
          overflow: hidden;
          order: 2;
        }
          .main-rotator-wrapper:before {
            content: "";
            display: block;
            height: 0px;
            padding: 350px 0px 0px; /*41.1765%*/
          }
            .main-rotator-wrapper.slick-initialized:before {
              display: none;
            }

            .main-rotator-slide {
              position: relative;
              z-index: 1;
            }
              .main-rotator-wrapper > .main-rotator-slide {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
              }
                .main-rotator-wrapper > .main-rotator-slide:first-child {
                  z-index: 2;
                }

              .main-rotator-slide-image {
                background-color: #e0e0e0;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                max-height: 350px;
                position: relative;
                z-index: 1;
              }
                .main-rotator-slide-image:before {
                  content: "";
                  display: block;
                  background: #000;
                  font-size: 0px;
                  height: 0px;
                  padding: 350px 0px 0px;
                  opacity: 0.25;
                }

                .main-rotator-slide-image a {
                  display: block;
                  color: transparent;
                  font-size: 0px;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  z-index: 1;
                }

              .main-slide-code-container {
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                padding: 20px 35px 50px;
                overflow: hidden;
                z-index: 2;
              }

                .main-slide-code-container > div {
                  z-index: 2;
                  position: relative;
                }
                .main-slide-code-container .slide-title {
                  font-size: 32px;
                  font-weight: 600;
                  letter-spacing: 0.05em;
                  text-shadow: 1px 1px 3px #2f2f2f;
                }
                .main-slide-code-container > div + div {
                  padding-top: 25px;
                }
                  .main-slide-code-container > div + div .button-style {
                    font-size: 18px;
                  }


          .main-rotator-dots {
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 3;
          }
            .main-rotator-dots > ul {
              display: flex;
              flex-flow: row wrap;
              justify-content: center;
              align-content: flex-start;
              align-items: center;
              min-height: 30px;
              margin: 0px 0px 0px -5px;
            }
              .main-rotator-dots li {
                padding: 0px 0px 5px 5px;
              }
                .main-rotator-dots li > div {
                  border: 1px solid #fff;
                  border-radius: 50%;
                  width: 8px;
                  height: 8px;
                  cursor: pointer;

                  -webkit-transition: background .3s ease 0s, width .3s ease 0s, height .3s ease 0s, margin .3s ease 0s;
                  -moz-transition: background .3s ease 0s, width .3s ease 0s, height .3s ease 0s, margin .3s ease 0s;
                  -o-transition: background .3s ease 0s, width .3s ease 0s, height .3s ease 0s, margin .3s ease 0s;
                  transition: background .3s ease 0s, width .3s ease 0s, height .3s ease 0s, margin .3s ease 0s;
                }
                  .main-rotator-dots li.slick-active > div {
                    background: #fff;
                    width: 10px;
                    height: 10px;
                    margin: -1px;
                  }


    .breadcrumbs {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      font-size: 12px;
      line-height: 0.95;
      padding: 0px 0px 12px;
      margin: -6px 0px 0px;
      position: relative;
      z-index: 3;
    }
      .breadcrumbs > li {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        margin: 6px 0px 0px;
      }
        .breadcrumbs > li:after {
          content: "→";
          color: #959595;
          font-size: 13px;
          margin: auto 5px;
          order: 2;
        }
          .breadcrumbs > li:last-child:after {
            display: none;
          }

        .breadcrumbs > li > div {
          order: 1;
          position: relative;
          z-index: 0;
        }
          .breadcrumbs > li > div:hover {
            z-index: 1;
          }

          .breadcrumbs > li > div > a,
          .breadcrumbs > li > div > span a {
            display: inline-block;
            color: #b22222;
            cursor: pointer;
            text-decoration: underline;
          }
            .breadcrumbs > li > div > span a:hover,
            .breadcrumbs > li > div > a:hover {
              text-decoration: none;
            }

          .breadcrumbs > li > div > span {
            display: inline-block;
            border-bottom: 1px dashed transparent;
            cursor: default;
          }
            .breadcrumbs > li > div > div + span {
              border-bottom-color: #000;
              cursor: pointer;
            }
              .breadcrumbs > li > div:hover > div + span {
                border-bottom-color: transparent;
              }


          .breadcrumbs > li > div > div {}
            .breadcrumbs > li > div > div:before {
              content: "";
              display: none;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 7px solid #b22222;
              position: absolute;
              left: calc(50% - 6px);
              bottom: -10px;
              height: 0px;
              width: 0px;
              z-index: 0;
            }
              .breadcrumbs > li > div:hover > div:before {
                display: block;
              }

            .breadcrumbs > li > div > div > ul {
              display: block;
              line-height: 1.3;
              position: absolute;
              top: 100%;
              left: 0px;
              min-width: 100%;
              padding: 9px 0px 0px;
              white-space: nowrap;
              z-index: 1;

              visibility: hidden;
              opacity: 0;
            }
              .breadcrumbs > li > div:hover > div > ul {
                visibility: visible;
                opacity: 1;
              }

              .breadcrumbs > li > div > div > ul > li {
                background: #f4f4f5;
                border: solid #e7e7e7;
                border-width: 0px 1px;
                padding: 0px 12px;
              }
                .breadcrumbs > li > div > div > ul > li:first-child {
                  border-top: 2px solid #b22222;
                  padding-top: 6px;
                }
                .breadcrumbs > li > div > div > ul > li:last-child {
                  border-bottom: 1px solid #e7e7e7;
                  padding-bottom: 6px;
                }

                .breadcrumbs > li > div > div > ul > li a {
                  display: inline-block;
                  padding: 3px 0px;
                  text-decoration: none;
                  cursor: pointer;
                }
                  .breadcrumbs > li > div > div > ul > li a:hover {
                    color: #b22222;
                    text-decoration: underline;
                  }


    .subheader-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
      .why-we-button {
        padding: 0px 0px 12px 20px;
      }
        .why-we-button .button-style {
          padding: 7px 15px;
          white-space: nowrap;
        }

    .subheader,
    .subsubheader,
    .subsubheader-top,
    .subsubsubheader {
      color: #000;
      margin: 0px;
      position: relative;
      z-index: 2;
    }
      .subheader {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.2;
        padding: 16px 0px 24px;
      }

      .subsubheader,
      .subsubheader-top {
        /*color: #f07f00;*/
        color: #b22222;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.3;
        padding: 14px 0px;
      }
        .subheader + .subsubheader,
        .subsubheader-top {
          padding-top: 0px;
        }

      .subsubsubheader {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.3;
        padding: 4px 0px 10px;
      }


    .text-container {
      padding: 0px 0px 25px;
      position: relative;
      z-index: 1;
    }


    .incut {
      background: #f4f4f5;
      border-left: 10px solid #f07f00;
      padding: 25px 30px;
      margin: 0px 0px 25px;
      position: relative;
    }

    .incut-2 {
      padding: 10px 25px 25px 25px;
      margin: 0px 0px 25px;
      position: relative;
      overflow: hidden;
    }
      .incut-2:before {
        content: "";
        background: url('/i/incut-2-bg.jpg') no-repeat 100% 0%/cover #f4f4f5;
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: -10%;
      }
      .incut-2 > div {
        position: relative;
        z-index: 1;
      }


    .advantages-block-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      padding: 0px 0px 25px;
      margin: -20px 0px 0px -20px;
      position: relative;
    }
      .advantages-block-container > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 25%;
        padding: 20px 0px 0px 20px;
        position: relative;
      }
        .advantages-block-container > div > div:first-child {
          max-width: 52px;
          min-width: 52px;
          margin-right: 14px;
        }
          .advantages-block-container > div > div:first-child img {
            width: 100%;
          }

        .advantages-block-container > div > div + div {
          font-size: 12px;
        }


    .tab-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.3;
      padding: 0px 0px 12px;
      margin: 0px -16px;
      position: relative;
      z-index: 1;
    }
      .tab-list a {
        color: #f07f00;
        display: block;
        padding: 8px 16px;
      }
        .tab-list .active a {
          color: #2f2f2f;
        }

        .tab-list a span {
          border-bottom: 2px solid transparent;
        }
          .tab-list .active a span {
            border-bottom-color: #f07f00;
          }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      padding: 0px 0px 25px;
      margin: -14px 0px 0px -14px;
      position: relative;
    }
      .gallery-item {
        padding: 14px 0px 0px 14px;
        position: relative;
      }
        .gallery-item-width-1 {
          width: 100%;
        }
        .gallery-item-width-2 {
          width: 50%;
        }
        .gallery-item-width-3 {
          width: 33.33333333%;
        }
        .gallery-item-width-4 {
          width: 25%;
        }

        .gallery-item img {
          width: 100%;
        }

      .gallery-notice {
        margin: -8px 0px 0px !important;
        position: relative;
      }


    .list-view {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;

      padding: 0px 0px 25px;
      margin: -26px 0px 0px -26px;
      position: relative;
    }
      .list-view-item {
        width: 25%;
        padding: 26px 0px 0px 26px;
        position: relative;
      }
        .with-left .list-view-item {
          width: 33.33333333%;
        }

        .list-view-item.empty {
          color: #b22222;
          font-weight: 600;
          width: 100% !important;
        }

        .list-view-item-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          border: 1px solid #e7e7e7;
          width: 100%;
          height: 100%;
          position: relative;
        }
          .list-view-item-image {
            margin: 0px 0px 14px;
            position: relative;
            order: 1;
            z-index: 1;
          }
            .list-view-item-image > a,
            .list-view-item-image > span {
              display: block;
              background: no-repeat 50% 50%/cover #eaeaea;
              height: 0px;
              padding: 58% 0% 0%;
              z-index: 1;
            }
              .list-view-item-image > a {
                cursor: pointer;
              }

          .list-view-item-text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            height: 100%;
            padding: 0px 8px;
            margin: 0px;
            order: 2;
            z-index: 2;
          }
            .list-view-item-name {
              font-size: 16px;
              text-align: center;
              text-decoration: none;
              margin: 0px 4px 12px;
              position: relative;
              order: 2;
              z-index: 2;
            }
              .list-view-item-name a {
                cursor: pointer;
              }
                .list-view-item-name a:hover,
                .list-view-item-image:hover + .list-view-item-name a,
                .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
                  text-decoration: underline;
                }

            .list-view-item-notice {
              margin: 0px 0px 14px;
              order: 3;
            }
              .list-view-item-notice :last-child {
                margin-bottom: 0px;
              }


    .list-view-line {
      padding: 0px 0px 25px;
      margin: -20px 0px 0px;
      position: relative;
    }
      .list-view-line-item {
        padding: 20px 0px 0px;
      }
        .list-view-line-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
          .list-view-line-item-image a,
          .list-view-line-item-image a img {
            cursor: pointer;
          }

          .list-view-line-item-text {
            width: 100%;
            padding: 20px 20px 20px 40px;
          }
            .list-view-line-item-name {
              font-size: 16px;
              font-weight: 600;
              text-decoration: none;
              padding: 0px 0px 12px;
            }
              .list-view-line-item-name a {
                cursor: pointer;
              }
                .list-view-line-item-name a:hover,
                .list-view-line-item-image:hover + .list-view-line-item-text .list-view-line-item-name a {
                  text-decoration: underline;
                }

            .list-view-line-item-notice {
              font-size: 12px;
            }
              .list-view-line-item-notice :last-child {
                margin-bottom: 0px;
              }


    .pager-row {
      padding: 0px 0px 22px;
    }
      .pager-row .ias_trigger,
      .pager-row .ias_loader {
        text-align: center;
      }
        .pager-row .ias_loader .button-style {
          color: transparent;
          background-color: transparent;
        }
          .pager-row .ias_loader .button-style:before {
            content: "";
            background: url('/i/ajax/ajax.gif') no-repeat 50% 50%/contain transparent;
            position: absolute;
            top: 8px;
            left: 0px;
            right: 0px;
            bottom: 8px;
          }


    .product-section-block-container .list-view {
      margin: -12px 0px 0px -12px;
    }
      .product-section-block-container .list-view-item {
        flex-grow: 17;
        flex-shrink: 0;
        width: auto;
        min-width: 27%;
        padding: 12px 0px 0px 12px;
      }
        .product-section-block-container .list-view-item:nth-child(6n),
        .product-section-block-container .list-view-item:nth-child(6n+1) {
          flex-grow: 25;
        }

        .product-section-block-container .list-view-item-wrapper {
          border: none;
        }
          .product-section-block-container .list-view-item-image {
            height: 100%;
            margin: 0px;
          }
            .product-section-block-container .list-view-item-image:before {
              content: "";
              display: block;
              font-size: 0px;
              height: 0px;
              padding: 55% 0% 0%;
            }

            .product-section-block-container .list-view-item-image > a,
            .product-section-block-container .list-view-item-image > span {
              height: auto;
              padding: 0px;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
            }


          .product-section-block-container .list-view-item-text {
            justify-content: flex-end;
            align-items: flex-start;
            height: auto;
            position: absolute;
            left: 0px;
            bottom: 0px;
            padding: 0px 16px 16px;
          }
            .product-section-block-container .list-view-item-name {
              display: inline-block;
              background: #f07f00;
              font-weight: 400;
              line-height: 1.1;
              text-align: left;
              padding: 10px 8px;
              margin: 0px;
            }
              .product-section-block-container .list-view-item-name a,
              .product-section-block-container .list-view-item-name span {
                color: #fff;
              }


    .product-collection-small-block-container .list-view {
      margin: -10px 0px 0px -10px;
    }
      .product-collection-small-block-container .list-view-item {
        width: 16.66666666%;
        padding: 10px 0px 0px 10px;
      }
        .collection-small-wrapper {
          height: 100%;
        }
          .collection-small-wrapper a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #e7e7e7;
            line-height: 1;
            width: 100%;
            height: 100%;
            padding: 12px;
            text-decoration: none;
            position: relative;
            cursor: pointer;
          }
            .collection-small-wrapper span:first-child {
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              position: relative;
              max-width: 24px;
              min-width: 24px;
              margin-right: 10px;
              overflow: hidden;
            }
              .collection-small-wrapper span:first-child:before {
                content: "";
                display: block;
                position: relative;
                height: 0px;
                padding-top: 100%;
              }

            .collection-small-wrapper span + span {
              font-size: 11px;
              font-weight: 600;
              padding: 2px 0px 0px;
            }
              .collection-small-wrapper a:hover span + span {
                text-decoration: underline;
              }


    #product-list .product-block-container {
      opacity: 1;

      -webkit-transition: opacity .3s ease-in-out 0s;
      -moz-transition: opacity .3s ease-in-out 0s;
      -o-transition: opacity .3s ease-in-out 0s;
      transition: opacity .3s ease-in-out 0s;
    }
      #product-list.list-view-loading .product-block-container {
        opacity: 0.2;
      }

      .product-block-container .list-view {
        margin: -22px 0px 0px -22px;
      }
        .product-block-container .list-view-item {
          padding: 22px 0px 0px 22px;
        }

          #search-list .product-block-container .list-view-item {
            width: 20%;
          }

          .product-block-container .list-view-item-image {
            margin-top: 20px;
          }
            .product-block-container .list-view-item-image > a {
              background-color: transparent;
              background-size: contain;
              padding-top: 72.16495%;
            }
              .product-block-container .list-view-item-image > a img {
                display: none;
              }

          .product-block-container .list-view-item-name {
            font-size: 13px;
            margin: 0px 0px 20px;
          }

          .product-view-item-params {
            color: #959595;
            font-size: 11px;
            text-align: center;
            margin: 0px 0px 20px;
          }
            .product-view-item-params > div + div {
              margin: 0.5em 0em 0em;
            }

          .product-view-item-price-container {
            text-align: center;
            padding: 0px 0px 20px;
          }
            .product-view-item-price {
              display: flex;
              flex-direction: column-reverse;
              justify-content: flex-end;
              align-items: stretch;
              font-size: 13px;
              padding: 0px 0px 10px;
            }
              .product-view-item-price > div {
                vertical-align: baseline;
              }
                .product-view-item-price > div + div {
                  color: #959595;
                  font-size: 11px;
                  padding: 0px 0px 4px;
                }

                .product-view-item-price > div > span {
                  color: #f07f00;
                  font-size: 14px;
                  font-weight: 600;
                  vertical-align: baseline;
                }
                  .product-view-item-price > div + div > span {
                    text-decoration: line-through;
                  }

          .product-buttons-container .button-style {
            font-size: 13px;
            padding: 8px 9px;
          }

          .product-labels-left,
          .product-labels-right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 10px;
            line-height: 1;
            position: absolute;
            top: 0px;
            z-index: 5;
          }
          .product-labels-left {
            left: -1px;
          }
          .product-labels-right {
            right: -1px;
          }
            .product-labels-left > div,
            .product-labels-right > div {
              color: #fff;
              padding: 5px 6px;
              margin: 8px 0px 0px;
              position: relative;
            }
            .product-labels-left .spec {
              background: #f381f5;
            }

            .product-labels-left .recommend,
            .product-labels-right .novelty {
              background: #0de48a;
            }

              .product-labels-left div span,
              .product-labels-right div span {
                display: block;
                position: absolute;
                top: 0px;
                bottom: 0px;
                z-index: -1;
              }
              .product-labels-left div span {
                right: 0px;
              }
              .product-labels-right div span {
                left: 0px;
              }

                .product-labels-left div span img,
                .product-labels-right div span img {
                  height: 100%;
                }

                .product-labels-left div span:before,
                .product-labels-right div span:before {
                  background-repeat: no-repeat;
                  background-position: 50% 50%;
                  background-size: cover;
                  content: "";
                  top: 0px;
                  bottom: 0px;
                  position: absolute;
                }
                .product-labels-left div span:before {
                  left: 50%;
                  right: -50%;
                }
                .product-labels-right div span:before {
                  left: -50%;
                  right: 50%;
                }

                .product-labels-left .spec span:before {
                  background-image: url('/i/product-label-arrow--purple.svg');
                }
                .product-labels-left .recommend span:before,
                .product-labels-right .novelty span:before {
                  background-image: url('/i/product-label-arrow--green.svg');
                }
                .product-labels-right .novelty span:before {
                  transform: scaleX(-1);
                }


    .product-card {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 6px 0px 30px;
    }
      .product-gallery-container {
        width: 360px;
        min-width: 360px;
        min-height: 240px;
        position: relative;
      }
        .product-gallery {
          width: 100%;
          max-height: 240px;
          position: relative;
          overflow: hidden;
        }
          .product-gallery:before {
            content: "";
            display: block;
            height: 0px;
            padding: 240px 0px 0px; /*66.666666%*/
          }
            .product-gallery.slick-initialized:before {
              display: none;
            }

            .product-gallery-image {
              position: relative;
              z-index: 1;
            }
              .product-gallery > .product-gallery-image {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
              }
                .product-gallery > .product-gallery-image:first-child {
                  z-index: 2;
                }

              .product-gallery-image > div {
                background-color: #fff;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: contain;
                max-height: 240px;
                position: relative;
                z-index: 1;
              }
                .product-gallery-image > div:before {
                  content: "";
                  display: block;
                  font-size: 0px;
                  height: 0px;
                  padding: 240px 0px 0px;
                }

                .product-gallery-image > div a {
                  display: block;
                  color: transparent;
                  font-size: 0px;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  cursor: pointer;
                  z-index: 1;
                }

        .product-gallery-pager ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 10px 0px 0px -6px;
        }
          .product-gallery-pager li {
            width: 16.66666666%;
            padding: 6px 0px 0px 6px;
          }
            .product-gallery-pager li > div {
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: contain;
              border: 1px solid #e7e7e7;
              height: 0px;
              padding-top: 66.037736%;
              cursor: pointer;
            }
              .product-gallery-pager li.slick-active > div {
                border-color: #f07f00
              }

      .product-card-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        padding: 50px 0px 0px 32px;
      }
        .product-order-container {
          flex: 1 2 100%;
          white-space: nowrap;
          text-align: center;
        }
          .product-card-price {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            color: #f07f00;
            font-weight: 700;
            font-size: 26px;
            padding: 0px 0px 14px;
          }
            .product-card-price > span,
            .product-card-price > div {
              color: #2f2f2f;
              font-weight: 400;
            }
              .product-card-price > span {
                padding-left: 6px;
              }
                .product-card-price > span:first-child {
                  display: none;
                }

          .product-card-price-old {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            color: #959595;
            font-size: 16px;
            font-weight: 600;
            padding: 0px 0px 14px;
            margin: -6px 0px 0px;
          }
            .product-card-price-old > span {
              font-size: 14px;
              font-weight: 400;
            }
              .product-card-price-old > span:first-child {
                padding-right: 4px;
              }
              .product-card-price-old > span ~ span {
                padding-left: 4px;
              }

          .product-order-button {
            padding: 0px 0px 12px;
          }
            .product-order-button .button-style {
              font-weight: 700;
              text-transform: uppercase;
              padding: 10px 35px;
            }

          .product-callback-link {
            font-size: 13px;
          }
            .product-callback-link a {
              color: #4f9ffe;
              display: inline-block;
              border-bottom: 1px dashed #4f9ffe;
              line-height: 1;
              text-decoration: none;
              cursor: pointer;
            }
              .product-callback-link a:hover {
                border-bottom-color: transparent;
              }

        .product-guarantee-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: stretch;
          flex: 1 3 100%;
          color: #959595;
          font-size: 12px;
          border-left: 1px solid #e7e7e7;
          padding: 15px 0px 15px 24px;
          margin-left: 24px;
        }
          .product-guarantee-container :last-child {
            margin-bottom: 0px;
          }

          .product-guarantee {
            font-size: 16px;
            font-weight: 600;
            padding: 0px 0px 16px;
          }

    .product-content-container {
      padding: 0px 0px 25px;
    }
      .product-content-tabs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        padding: 1px 1px 0px;
        line-height: 1;
      }
        .product-content-tabs > a {
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex: 1 1 100%;
          font-size: 13px;
          background: #f4f4f5;
          border: 1px solid #e7e7e7;
          font-weight: 700;
          text-transform: uppercase;
          padding: 13px;
          margin: -1px -1px 0px;
          cursor: pointer;
          position: relative;
        }
          .product-content-tabs > a:hover {
            background: #e7e7e7;
          }
          .product-content-tabs > a.active {
            color: #fff;
            background: #f07f00;
            border-color: #e15c01;
            cursor: default;
            z-index: 1;
          }

      .product-content-wrapper {
        font-size: 13px;
        line-height: 1.5;
        border: 1px solid #e7e7e7;
        border-top: none;
        padding: 24px 20px 0px;
      }
        .product-content-wrapper > .hidden {
          display: none !important;
        }

        .product-content-wrapper #content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }
          .product-content {
            width: 100%;
          }
            .product-params {
              padding: 0px 0px 25px;
            }
              .product-params > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                position: relative;
              }
                .product-params > div + div {
                  margin-top: 8px;
                }

                .product-params > div > span:first-child {
                  background: #fff;
                  flex: 0 0 auto;
                  white-space: nowrap;
                  order: 1;
                  padding-right: 2px;
                  position: relative;
                  z-index: 2;
                }
                .product-params > div > span + span {
                  flex: 1 1 auto;
                  text-align: right;
                  max-width: 60%;
                  order: 3;
                  z-index: 1;
                }
                  .product-params > div > span + span > span {
                    background: #fff;
                    position: relative;
                    z-index: 1;
                  }

                  .product-params > div > span + span:after {
                    content: "";
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: 3px;
                    border-bottom: 1px dotted #e7e7e7;
                    z-index: 0;
                  }


          .product-content-right {
            color: #959595;
            font-size: 12px;
            width: 275px;
            min-width: 275px;
            padding: 0px 0px 6px 20px;
          }


    #fast-order-popup-basket.form-popup-container .form-block-container {
      width: auto;
    }
      .fast-order-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
      }
        .product-fast-order-info {
          width: 350px;
          margin-right: 25px;
        }
          .product-fast-order-name {
            font-weight: 600;
            margin-bottom: 25px;
          }

          .product-fast-order-image {
            background: no-repeat 50% 50%/contain transparent;
          }
            .product-fast-order-image:before {
              content: "";
              display: block;
              height: 0px;
              padding-top: 70%;
            }

        #fast-order-popup-basket .fast-order-wrapper .form-container {
          width: 302px;
        }


    .brands-block-container .list-view-item {
      width: 25%;
    }
      .brands-block-container .list-view-item-image {
        margin: 8px;
      }
        .brands-block-container .list-view-item-image > a {
          background-color: transparent;
          background-size: contain;
          padding: 40% 0% 0%;
        }
          .brands-block-container .list-view-item-image > a img {
            display: none;
          }

      .brands-block-container .list-view-item-name {
        display: none;
      }


    .product-brands-block-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      background: #f4f4f5;
      border: 1px solid #e7e7e7;
      padding: 6px 14px 10px;
      margin: 0px 0px 20px;
      position: relative;
      z-index: 2;
    }
      .product-brands-block-container > div {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 4px 0px 0px;
      }
        .product-brands-block-container > div:not(:last-child):after {
          content: "/";
          margin: 0px 6px;
        }

        .product-brands-block-container > div a {
          text-decoration: underline;
          cursor: pointer;
        }
          .product-brands-block-container > div a:hover {
            text-decoration: none;
          }
          .product-brands-block-container > div.active a {
            color: #b22222;
            font-weight: 600;
            text-decoration: none;
            cursor: default;
          }
    /*.product-brands-block-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;
      margin: 0px 0px 0px -14px;
      position: relative;
      z-index: 1;
    }
      .product-brands-block-container > div {
        width: 97px;
        padding: 0px 0px 14px 14px;
      }
        .with-left .product-brands-block-container > div {
          width: 87px;
        }

        .product-brands-block-container > div > div {
          height: 100%;
          border: 1px solid #e7e7e7;
        }
          .product-brands-block-container > div > div:hover,
          .product-brands-block-container > div.active > div {
            border-color: #f07f00;
          }

          .product-brands-block-container > div > div > a {
            background: no-repeat 50% 50%/contain transparent;
            display: block;
            border: 3px solid #fff;
            text-align: center;
            cursor: pointer;
            text-decoration: none;
          }
            .product-brands-block-container > div:first-child > div > a {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              font-weight: 700;
              text-align: center;
              text-transform: uppercase;
              height: 100%;
              padding: 0px;
            }

            .product-brands-block-container > div > div > a > span {
              content: "";
              color: transparent;
              display: block;
              font-size: 0px;
              height: 0px;
              padding: 53.84615385% 0% 0%;
            }*/


    .pager-block-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: #f4f4f5;
      border: 1px solid #e7e7e7;
      line-height: 1.2;
      padding: 10px 14px;
      margin: 0px 0px 22px;
      position: relative;
      z-index: 2;
    }
      .pager-block-container > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
        .pager-block-container .sorting-container .input-container,
        .pager-block-container .pagesize-container .input-container {
          background-color: transparent;
          border: none;
          padding: 0px 0px 0px 0.5em;
        }
          .pager-block-container .sorting-container .input-container select,
          .pager-block-container .pagesize-container .input-container select {
            background: url('/i/arrow-down--orange.svg') no-repeat right 4px center/10px auto #fff;
            border: 1px solid #f07f00;
            display: inline-block;
            line-height: 1;
            width: auto;
            height: 22px;
            padding: 2px 18px 2px 4px;
            cursor: pointer;
          }


        .pager-block-container ul.pager {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
        }
          .pager-block-container ul.pager > li {
            height: 22px;
            margin: 0px 0px 0px 7px;
          }
            .pager-block-container ul.pager > li > a {
              display: block;
              background: #fff;
              border: 1px solid #e7e7e7;
              text-align: center;
              width: 30px;
              height: 100%;
              padding: 2px;
              cursor: pointer;
            }
              .pager-block-container ul.pager > li.active > a {
                border-color: #f07f00;
              }

            .pager-block-container ul.pager > li.first,
            .pager-block-container ul.pager > li.last {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: baseline;
            }
              .pager-block-container ul.pager > li.first:after,
              .pager-block-container ul.pager > li.last:before {
                content: "\2026";
              }
              .pager-block-container ul.pager > li.first:after {
                margin: 0px 0px 0px 7px;
              }
              .pager-block-container ul.pager > li.last:before {
                margin: 0px 7px 0px 0px;
              }

            .pager-block-container ul.pager > li.previous,
            .pager-block-container ul.pager > li.next {
              width: 14px;
            }
              .pager-block-container ul.pager > li.previous > a,
              .pager-block-container ul.pager > li.next > a {
                font-size: 0px;
                background: no-repeat 50% 50%/auto 14px transparent;
                border: none;
                width: 100%;
                padding: 0px;
              }
              .pager-block-container ul.pager > li.previous > a {
                background-image: url('/i/arrow-left--orange.svg');
              }
              .pager-block-container ul.pager > li.next > a {
                background-image: url('/i/arrow-right--orange.svg');
              }


    .service-block-container .list-view .list-view-item {
      width: 33.33333333%;
    }


    .list-page-group-name ~ .service-block-container + .service-block-container {
      padding-top: 20px;
    }


    .service-block-container .list-view-line-item-image {
      width: 25%;
      min-width: 25%;
    }
      .service-block-container .list-view-line-item-image img {
        width: 100%;
      }

    .service-block-container .list-view-item-name {
      font-size: 14px;
      font-weight: 600;
      margin: 0px 3px 10px;
    }

    .service-block-container .list-view-item-notice,
    .service-block-container .list-view-line-item-notice {
      font-size: 13px;
    }


    .service-block-container .list-view-line-wrapper {
      align-items: flex-start;
      border: 1px solid #e7e7e7;
    }
      .service-block-container .list-view-line-item-name a {
        text-decoration: underline;
      }
        .service-block-container .list-view-line-item-name a:hover,
        .service-block-container .list-view-line-item-image:hover + .list-view-line-item-text .list-view-line-item-name a {
          text-decoration: none;
        }


    .news-block-container .list-view-item-wrapper {
      border: none;
    }
      .news-block-container .list-view-item-image > a {
        border-radius: 3px;
      }
      .news-block-container .list-view-item-notice {
        font-size: 12px;
        text-align: center;
        margin-bottom: 0px;
      }


    .portfolio-block-container .list-view-line-wrapper,
    .recommendation-block-container .list-view-line-wrapper {
      background: #f4f4f5;
    }
      .portfolio-block-container .list-view-line-item-image,
      .recommendation-block-container .list-view-line-item-image {
        background: #fff;
        border: 1px solid #999;
        padding: 2px;
      }

      .portfolio-block-container .list-view-line-item-notice,
      .recommendation-block-container .list-view-line-item-notice {
        font-size: 11px;
      }


    .portfolio-clients-rotator {
      padding: 0px 23px 25px;
      position: relative;
    }
      .portfolio-clients-rotator-arrows {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 25px;
        z-index: 0;
      }
        .portfolio-clients-rotator-arrows > div {
          background: no-repeat 50% 50%/contain transparent;
          width: 16px;
          position: absolute;
          top: 0px;
          bottom: 0px;
          cursor: pointer;
        }
        .portfolio-clients-rotator-arrows > div:first-child {
          background-image: url('/i/arrow-left--orange.svg');
          left: 0px;
        }
        .portfolio-clients-rotator-arrows > div + div {
          background-image: url('/i/arrow-right--orange.svg');
          right: 0px;
        }

      .portfolio-clients-rotator-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        overflow: hidden;
        position: relative;
        z-index: 1;
      }
        .portfolio-clients-rotator-wrapper > div {
          flex: 1 1 auto;
        }

        .portfolio-client-item {
          width: calc(100% / 6);
          max-width: calc(100% / 6);
          min-width: calc(100% / 6);
          position: relative;
        }
          .with-left .portfolio-client-item {
            width: calc(100% / 5);
            max-width: calc(100% / 5);
            min-width: calc(100% / 5);
          }
          .portfolio-clients-rotator-wrapper.slick-initialized .portfolio-client-item {
            max-width: unset !important;
            min-width: unset !important;
          }

          .portfolio-client-item > div {
            padding: 0px 20px;
          }
            .with-left .portfolio-client-item > div {
              padding: 0px 9px;
            }

            .portfolio-client-item > div > div {
              background: no-repeat 50% 50%/contain transparent;
            }
              .portfolio-client-item > div > div:before {
                content: "";
                display: block;
                height: 0px;
                padding-top: 75%;
              }


    .selected-filters-container {
      color: #fff;
      background: #f07f00;
      border: 1px solid #e15c01;
      line-height: 1.1;
      margin: 0px 0px 25px;
    }
      .selected-filters-container > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        padding: 16px;
      }
        .selected-filters-container > div:first-child span {
          font-weight: 600;
        }
        .selected-filters-container > div:first-child a {
          font-size: 12px;
          color: #fff;
          border-bottom: 1px dashed #fff;
          cursor: pointer;
        }
          .selected-filters-container > div:first-child a:hover {
            border-bottom-color: transparent;
          }

      .selected-filters-container > div + div {
        padding: 0px 16px;
      }
        .selected-filters-container > div + div > div {
          border-top: 1px solid #fff;
          padding: 16px 0px;
        }
          .selected-filters-container > div + div > div > div {
            font-size: 12px;
          }
          .selected-filters-container > div + div > div > div:first-child {
            font-weight: 600;
            padding: 0px 0px 10px;
          }

          .selected-filters-container > div + div > div > div + div {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: center;
            margin: -6px 0px 0px -6px;
          }
            .selected-filters-container > div + div > div > div + div a {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              background: #fff;
              /*border: 1px solid #959595;*/
              border-radius: 3px;
              padding: 3px 6px;
              margin: 6px 0px 0px 6px;
              cursor: pointer;
            }
              .selected-filters-container > div + div > div > div + div a:after {
                content: "\00d7";
                color: #e15c01;
                margin: 0px 0px 0px 6px;
              }


    .filter-container {
      border: solid #e7e7e7;
      border-width: 0px 1px 1px;
    }
      .filter-elements-container > div {
        border-top: 1px solid #e7e7e7;
      }
        .filter-dropdown-button {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-weight: 600;
          line-height: 1.1;
          padding: 16px;
          cursor: pointer;
          position: relative;
          z-index: 1;
        }
          .filter-dropdown-button:before {
            content: "";
            background: url('/i/arrow-right--mgray.svg') no-repeat 50% 50%/contain transparent;
            width: 10px;
            min-width: 10px;
            height: 10px;
            margin-right: 10px;
          }
            .is-opened .filter-dropdown-button:before {
              background-image: url('/i/arrow-down--mgray.svg');
            }

          .filter-dropdown-button.is-selected {
            /*color: #f07f00;
            background: #f4f4f5;*/
            border: 1px solid #f07f00/*#e15c01*/;
            margin: -1px;
          }
            .is-opened .filter-dropdown-button.is-selected {
              border-width: 1px 1px 0px;
              margin: -1px -1px 0px;
            }
            /*.filter-dropdown-button.is-selected:before {
              content: "";
              background: url('/i/arrow-right--orange.svg') no-repeat 50% 50%/contain transparent;
              width: 10px;
              height: 10px;
              margin-right: 10px;
            }
              .is-opened .filter-dropdown-button.is-selected:before {
                background-image: url('/i/arrow-down--orange.svg');
              }*/

      .filter-dropdown-container {
        display: none;
        padding: 0px 16px 18px;
        position: relative;
      }
        .is-opened .filter-dropdown-container {
          display: block;
        }
        .filter-dropdown-button.is-selected + .filter-dropdown-container {
          /*background: #f4f4f5;*/
          border: 1px solid #f07f00/*#e15c01*/;
          border-top: none;
          margin: 0px -1px -1px;
        }

        /*.filter-menu ul {
          padding-left: 20px;
        }*/
        .filter-menu ul,
        .filter-checkbox-list {
          margin-top: -6px;
        }
          .filter-menu li,
          .filter-checkbox-field {
            margin-top: 6px;
          }
            .filter-checkbox-field > input { display: none; }

            .filter-menu a {
              cursor: pointer;
              text-decoration: none;
            }
              .filter-menu li.active a {
                color: #b22222;
                cursor: text;
              }
              .filter-menu li.active a,
              .filter-menu a:hover {
                text-decoration: underline;
              }

            .filter-checkbox-field > label {
              display: inline-flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              font-size: 13px;
              cursor: pointer;
            }
              .filter-checkbox-field > input.disabled + label {
                color: #959595;
                cursor: default;
              }

              .filter-checkbox-field > label > span {
                background: #e6e6e6;
                border: 1px solid #959595;
                width: 13px;
                min-width: 13px;
                height: 13px;
                margin-right: 10px;
                position: relative;
              }
                .filter-checkbox-field > input:hover + label > span,
                .filter-checkbox-field > input:checked + label > span {
                  background-color: #f07f00;
                  border-color: #e15c01 !important;
                }
                  .filter-checkbox-field > input:checked + label > span:before {
                    content: "●";
                    color: transparent;
                    display: block;
                    background: url('/i/filter-checkbox-icon--white.svg') no-repeat 50% 50%/contain transparent;
                    width: 9px;
                    height: 9px;
                    margin: auto;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                  }

                .filter-checkbox-field > input.disabled + label > span {
                  background-color: #f4f4f5;
                  border-color: #e7e7e7 !important;
                }
                  .filter-checkbox-field > input.disabled + label > span:before {
                    background-image: url('/i/filter-checkbox-icon--gray.svg');
                  }

        .filter-slider-inputs {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin-left: -21px;
        }
          .filter-slider-inputs > label { display: none; }

          .filter-slider-inputs > div {
            width: 50%;
            padding-left: 21px;
          }
            .filter-slider-inputs .input-container {
              background: #fff;
              border: 1px solid #e6e6e6;
              padding: 6px 10px;
            }
              .filter-dropdown-button.is-selected + .filter-dropdown-container .filter-slider-inputs .input-container {
                border-color: #959595;
              }

              .filter-slider-inputs .input-container input {
                /*color: #959595;*/
                line-height: 1;
              }

        .filter-slider-container {
          display: none;
          padding: 18px 0px 0px;
          position: relative;
        }
          .filter-slider-container.is-created {
            display: block;
          }

          .filter-slider-container > div {
            background: #e6e6e6;
            border-radius: 999px;
            height: 3px;
            margin: 1px 4px;
            position: relative;
          }
            .filter-dropdown-button.is-selected + .filter-dropdown-container .filter-slider-container > div {
              background: #959595;
            }

            .filter-slider-container > div > div {
              background: #f07f00;
              position: absolute;
              top: -1px;
              bottom: -1px;
            }

            .filter-slider-container > div > span {
              display: block;
              background: #f07f00;
              border: 2px solid #e15c01;
              border-radius: 50%;
              position: absolute;
              top: -3px;
              bottom: -3px;
              width: 9px;
              margin-left: -4px;
              cursor: pointer;
            }
              .filter-slider-container > div > span > span { display: none; }


      .filter-buttons-container {
        border-top: 1px solid #e7e7e7;
        line-height: 1.1;
        padding: 16px;
      }
        .filter-buttons-container > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
          .filter-buttons-container > div > div {
            text-align: center;
            width: 50%;
          }
            .filter-buttons-container .filter-button-submit .button-style {
              font-size: 16px;
              min-width: auto;
              width: 100%;
              padding: 8px;
            }

            .filter-buttons-container .filter-button-reset a {
              display: inline-block;
              color: #959595;
              border-bottom: 1px dashed #959595;
              cursor: pointer;
            }
              .filter-buttons-container .filter-button-reset a:hover {
                border-bottom-color: transparent;
              }


    .left-image-container {
      border: 1px solid #e7e7e7;
      position: relative;
    }
      .left-image-container + div {
        margin-top: 25px;
      }

      .left-image-wrapper {
        position: relative;
        z-index: 1;
      }
        .left-image-wrapper a,
        .left-image-wrapper span {
          display: block;
          position: relative;
        }
        .left-image-wrapper a {
          cursor: pointer;
        }
          .left-image-wrapper a:before,
          .left-image-wrapper span:before {
            content: "";
            display: block;
            background: #000;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            opacity: 0.25;
            z-index: 2;
          }

          .left-image-wrapper img {
            width: 100%;
            position: relative;
            z-index: 1;
          }


      .left-image-code {
        color: #fff;
        position: absolute;
        left: 14px;
        right: 14px;
        bottom: 14px;
        z-index: 2;
      }
        .left-image-code > div + div {
          padding-top: 14px;
        }
        .left-image-title {
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0.05em;
          text-shadow: 1px 1px 3px #2f2f2f;
        }
          .left-image-title a {
            color: #fff;
            text-decoration: underline;
            cursor: pointer;
          }
            .left-image-title a:hover,
            .left-image-wrapper:hover + .left-image-code .left-image-title a {
              text-decoration: none;
            }


    .news-left-container {
      border: 1px solid #e7e7e7;
      padding: 20px;
    }
      .news-left-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 20px;
      }
        .news-left-title > div:first-child {
          font-size: 18px;
          font-weight: 600;
          line-height: 1.3;
        }

      .news-left-wrapper > div {
        padding: 18px 0px;
      }
        .news-left-wrapper > div + div {
          border-top: 1px solid #959595;
        }

        .news-left-wrapper > div > div:first-child {
          color: #959595;
          padding: 0px 0px 14px;
        }
        .news-left-wrapper > div > div + div a {
          font-weight: 600;
          text-decoration: none;
          cursor: pointer;
        }
          .news-left-wrapper > div > div + div a:hover {
            text-decoration: underline;
          }


    .news-content > p:first-child {
      color: #959595;
    }


    .team-block-container .list-view {
      margin-left: -35px;
    }
      .with-left .team-block-container .list-view {
        margin-left: -28px;
      }

      .team-block-container .list-view-item {
        width: 20%;
        padding-left: 35px;
      }
        .with-left .team-block-container .list-view-item {
          width: 25%;
          padding-left: 28px;
        }

        .team-block-container .list-view-item-wrapper {
          border: none;
        }
          .team-block-container .list-view-item-image > a,
          .team-block-container .list-view-item-image > span {
            padding-top: 79.20792079%;
          }

          .team-block-container .list-view-item-notice {
            font-size: 13px;
            text-align: center;
            margin-bottom: 0px;
          }


    .price-table {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 0px 0px 25px;
      position: relative;
    }
      .price-table > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
      }
        .price-table > div + div {
          border-top: 3px solid #fff;
        }

      .price-table-head {
        color: #fff;
        background: #f07f00;
        align-items: flex-end !important;
      }

      .price-table-subheader {
        background: #fff;
        border-top-width: 14px !important;
        z-index: 1;
      }

      .price-table-row {
        background: #f4f4f5;
      }
        .price-table-row.odd {
          background: #e7e7e7;
        }
        .price-name,
        .price-price,
        .price-unit {
          font-weight: 400;
          line-height: 1.2;
          padding: 10px 12px;
          flex-basis: 100%;
          flex-shrink: 5;
        }
        .price-price,
        .price-unit {
          flex-shrink: 14;
        }
          .price-table > div > span + span {
            border-left: 3px solid #fff;
          }

          .price-price,
          .price-table > div > span + .price-unit {
            text-align: center;
          }

          .price-name + .price-unit,
          .price-name + .price-unit ~ .price-unit,
          .price-price + .price-name,
          .price-unit + .price-name {
            flex-shrink: 7;
          }

          .price-price > span {
            font-size: inherit;
            width: 0px;
            height: 0px;
            padding: 0px 2px;
          }


          .price-table-head .price-name,
          .price-table-head .price-price,
          .price-table-head .price-unit {
            font-weight: 600;
          }
          .price-table-subheader .price-name {
            font-weight: 600;
            text-align: center;
          }


    .terms-block-container {
      padding: 0px 0px 25px;
      margin: -25px 0px 0px;
    }
      .terms-block-container > div {
        margin: 25px 0px 0px;
        position: relative;
      }
        .terms-block-container > div.active:before {
          content: "";
          /*background: #f4f4f5;*/
          border: 2px solid #f07f00;
          position: absolute;
          top: -12px;
          left: -12px;
          right: -12px;
          bottom: -12px;
          z-index: 1;
        }

        .terms-block-container .term-name {
          font-size: 18px;
          padding-bottom: 12px;
          position: relative;
          z-index: 2;
        }
          .terms-block-container .term-name a {
            color: #f07f00;
            border-bottom: 1px dashed transparent;
            font-weight: 600;
            text-decoration: none;
            cursor: pointer;
          }
            .terms-block-container .term-name a:hover {
              border-bottom-color: #f07f00;
            }

        .terms-block-container .term-name + div {
          position: relative;
          z-index: 2;
        }
          .terms-block-container .term-name + div p:last-child {
            margin-bottom: 0px;
          }

          .term-img {
            padding-bottom: 10px;
          }
            .term-img img {
              max-width: 100%;
            }


    .glossary-term {
      /*font-family: inherit;
      font-size: inherit;
      font-weight: inherit;*/
      display: inline-block;
      line-height: 1;
      /*color: #f07f00;*/
      color: inherit;
      border-bottom: 1px dashed;/* #2f2f2f /*#f07f00*/;
      text-decoration: none;
      vertical-align: baseline;
      cursor: pointer;
    }
      .glossary-term:hover {
        border-bottom-color: transparent;
      }


    .glossary-popup .form-block-container {
      width: 560px;
    }


    .icon-pdf {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
    }
      .icon-pdf:before {
        content: "";
        color: transparent;
        background: url(/i/file-icon--pdf.svg) no-repeat 50% 50%/contain #b00606;
        border: 2px solid #b00606;
        margin-right: 10px;
        width: 15px;
        min-width: 15px;
        height: 15px;
        min-height: 15px;
      }


    .projects-map-container {
      padding: 0px 0px 20px;
      position: relative;
    }
      .projects-map-container > div:first-child {
        height: 0px;
        padding: 66% 0% 0%;
        position: relative;
        z-index: 1;
      }
        .with-left .projects-map-container > div:first-child {
          padding: 88% 0% 0%;
        }

        .projects-map-container > div:first-child:before {
          content: "Загрузка карты...";
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
        }

      .projects-map-container > div + div {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 20px;
        z-index: 2;
      }
  /* Content block end */



  /* Contact page */
  .contact-page-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 0px 0px 25px;
  }
    .contact-info-left > div {
      padding-left: 50px;
      position: relative;
    }
    .contact-info-left > div + div {
      margin-top: 20px;
    }
      .contact-info-left > div:before {
        content: "";
        background-repeat: no-repeat;
        background-position: 50% 0%;
        background-size: contain;
        position: absolute;
        top: 1px;
        left: 0px;
        bottom: 0px;
        width: 30px;
      }
      .contact-page-phones:before {
        background-image: url('/i/contact-icon-phone.jpg');
      }
      .contact-page-emails:before {
        background-image: url('/i/contact-icon-email.jpg');
      }
      .contact-page-address:before {
        background-image: url('/i/contact-icon-address.jpg');
      }
      .contact-page-mail-address:before {
        background-image: url('/i/contact-icon-mail.jpg');
      }

      .contact-info-left .contact-label {
        display: block;
        font-weight: 600;
        padding: 0px 0px 5px;
      }

      .contact-phones > div + div {
        padding-top: 4px;
      }
        .contact-phones a {
          font-weight: 600;
          cursor: text;
        }

      .contact-emails a {
        color: #b22222;
        text-decoration: underline;
        cursor: pointer;
      }
        .contact-emails a:hover {
          text-decoration: none;
        }

    .contact-page-address :last-child {
      margin-bottom: 0px;
    }

    .contact-page-callback {
      width: 50%;
      padding-left: 28px;
      position: relative;
    }
      .contact-page-callback .form-label {
        font-weight: 400;
      }
      .contact-page-callback .form-row + .form-row {
        margin-top: 14px;
      }
      .contact-page-callback .form-buttons {
        justify-content: flex-start;
      }


  .contact-page-map {
    padding: 0px 0px 25px;
    position: relative;
  }
    .contact-page-map-hfull .contact-page-map {
      height: 100%;
    }
    .contact-page-map > div:first-child {
      height: 0px;
      padding: 65% 0% 0%;
      position: relative;
      z-index: 1;
    }
      .contact-page-map > div:first-child:before {
        content: "Загрузка карты...";
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }

    .contact-page-map > div + div {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 25px;
      z-index: 2;
    }
  /* Contact page end */



  /* Footer */
  .footer {
    font-size: 13px;
    background: #e7e7e7;
    padding: 30px 28px 25px;
    position: relative;
    z-index: 1;
  }
    .footer-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
    }
      .footer-left > div + div {
        padding-top: 22px;
      }

      .footer-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-grow: 1;
      }
        .footer-menu > ul {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: flex-start;
          width: 100%;
          padding-left: 50px;
          white-space: nowrap;
        }
          .footer-menu a,
          .footer-menu span {
            display: inline-block;
            padding: 2px 0px;
            text-decoration: none;
          }
            .footer-menu a {
              cursor: pointer;
              text-decoration: underline;
            }
            .footer-menu a:hover,
            .footer-menu .active a {
              text-decoration: none;
            }
            .footer-menu .active a {
              color: #b22222;
              font-weight: 700;
              cursor: default;
            }

            .footer-menu > ul > li > div > div > span {
              font-size: 15px;
              font-weight: 600;
              padding-top: 0px;
            }

      .footer-contact {
        padding-left: 50px;
      }
        .footer-contact-title {
          font-size: 15px;
          font-weight: 600;
        }

        .footer-contact > div + div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          padding-top: 6px;
        }
          .footer-contact-label {
            padding-right: 6px;
            white-space: nowrap;
          }
            .footer-contact-label + div {
              flex-grow: 1;
              font-weight: 600;
            }

            .footer-phones > div + div > div + div,
            .footer-emails > div + div > div + div {
              padding-top: 4px;
            }
              .footer-phones a,
              .footer-emails a {
                font-weight: 600;
              }
              .footer-phones a {
                cursor: text;
                text-decoration: none;
              }
              .footer-emails a {
                cursor: pointer;
                text-decoration: underline;
              }
                .footer-emails a:hover {
                  text-decoration: none;
                }


    .footer-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px 0px 0px;
      margin: 0px 0px 0px -20px;
    }
      .footer-bottom > div {
        flex: 1 1 auto;
        padding-left: 20px;
      }
  /* Footer end */


  .top-info-panel-container,
  .go-top-button-container {
    width: 1206px;
    height: 0px;
    margin: 0px auto;
    position: fixed;
    z-index: 999;
  }

  .top-info-panel-container {
    top: 0px;
  }
    #top-info-panel {
      padding-bottom: 5px;
      overflow: hidden;
      position: relative;
      visibility: hidden;
      opacity: 0;

      -webkit-transition: visibility .2s ease 0s, opacity .2s ease-out 0s;
      -moz-transition: visibility .2s ease 0s, opacity .2s ease-out 0s;
      -o-transition: visibility .2s ease 0s, opacity .2s ease-out 0s;
      transition: visibility .2s ease 0s, opacity .2s ease-out 0s;
    }
      .initialized > #top-info-panel.is-visible {
        visibility: visible;
        opacity: 1;
      }
      #top-info-panel:before {
        content: "";
        position: absolute;
        top: 0px;
        left: -14px;
        right: -14px;
        bottom: 5px;
        box-shadow: 0px 0px 6px -3px #000;
        z-index: 0;
      }


      #top-info-panel > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #f4f4f5;
        padding: 8px 28px;
        position: relative;
        z-index: 1;
      }
        #top-info-panel > div > div {
          position: relative;
          z-index: 1;
        }

        #top-info-panel .logo {
          min-width: 160px;
          max-width: 160px;
        }

        #top-info-panel .callback-link a {
          color: #b22222;
          font-size: 16px;
          display: inline-block;
          border-bottom: 1px dashed #b22222;
          line-height: 1;
          text-decoration: none;
          cursor: pointer;
        }
          #top-info-panel .callback-link a:hover {
            border-bottom-color: transparent;
          }

        #top-info-panel .phone {
          font-size: 20px;
          white-space: nowrap;
        }
          #top-info-panel .phone a {
            color: #b22222;
            cursor: default;
          }


  .go-top-button-container {
    bottom: 0px;
  }
    #go-top-button {
      display: block;
      color: transparent;
      background: url('/i/arrow-up--white.svg') no-repeat 50% 50%/20px auto #f07f00;
      width: 42px;
      height: 42px;
      cursor: pointer;
      position: absolute;
      right: 0px;
      bottom: 0px;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;

      -webkit-transition: visibility .2s ease 0s, opacity .2s ease-out 0s;
      -moz-transition: visibility .2s ease 0s, opacity .2s ease-out 0s;
      -o-transition: visibility .2s ease 0s, opacity .2s ease-out 0s;
      transition: visibility .2s ease 0s, opacity .2s ease-out 0s;
    }
      #go-top-button.is-visible {
        visibility: visible;
        opacity: 1;
      }
/* Structure end */